.tabs-parent{
    background-color: #F2F6F9;
    font-family:'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #28388E;
    align-items: center;
    text-align: center;
    caret-color: transparent;
    margin-bottom: 50px;
}

.active-tab{
    background-color: #28388E;
    color: white;
}

.tabs-filter{
    padding: 10px 0;
    cursor: pointer;
    height: 38px;
    text-transform: uppercase;
}

.aim-select{
    border: 1px solid #55606E80;
    font-size: 14px;
    color: #55606E;
    font-family: 'Montserrat', sans-serif;
    height: 50px;
    width: 100%;
}

.goal{
    font-size: 14px;
    white-space: pre-wrap;
}



.search-btn{
    width: 300px;
    margin-top: 0;
    align-self: end;
}

.filter-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #55606E;
    margin-bottom: 16px;
}

.show-more-btn{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #28388E;
    border: none;
    background: transparent;
}
.block-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin: 22px 0;
}

.news-line{
    max-width: 80px;
}

.news-swiper-container{
    position: relative;
    margin-bottom: 30px;
}

.swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    width: 50%;
}

.hide {
    display: none;
}

.navigation-btns-p{
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #00A4DC;
}
.swiper-pagination .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
}


.swiper-button-prev-news,
.swiper-button-next-news
{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #00A4DC;
    color: white;
}

.swiper-button-disabled{
    width: 44px;
    height: 44px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
}