

*,
*:before,
*:after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

#root{
  overflow: auto;
}

body{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.line{
  border-bottom: 2px solid #EDEFF0;
}

.blue-line{
  border-bottom: 2px solid #28388E;
  max-width: 249px;
}

.goal-blue-line{
  border-bottom: 2px solid #28388E;
  max-width: 350px;
}


.blue-main-btn{
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #00A4DC;
  height: 50px;
  width: 300px ;
  border: none;
  font-weight: 600;
  caret-color: transparent;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 16px;

}

.blue-main-btn:hover{
  background: linear-gradient(90deg, rgba(0,164,220,1) 0%, rgba(13,105,136,1) 41%, rgba(0,164,220,1) 100%);
}

.block-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 22px 0;
}
