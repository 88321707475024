.resource-card{
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    padding: 32px;
    margin: 16px 0;
    width: 100%;
}

.resource-card-inner{
    display: flex;
}

.resource-card-inner img{
    display: block;
    margin-right: 8px;
    width: 48px;
}
.resource-contact p{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin: 0;
}

.resource-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 17px;
    text-align: left;
}


.resource-contact{
    text-align: right;
}

.resource-contact a{
    text-decoration: none;
    color: #00A4DC;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: block;

}

.emblem{
    width: 86px;
    height: 86px;
}