
.lang-icon {
    width: 20px;
}

.search-icon {
    width: 20px;
    margin: 0 8px;
    cursor: pointer;
}

.purple-logo {
    padding: 2px 0 2px 8px;
    height: 65px;
}


.language-box {
    display: flex;
    align-items: center;
    padding-left: 20px;
    caret-color: transparent;
}

.navigation-btn{
    font-size: 12px;
}

@media screen and (min-width: 950px) {
    .search-icon {
        width: 20px;
        margin: 0 3px;
        cursor: pointer;
    }
    .language-box {
        padding-left: 5px;
    }
}

@media screen and (min-width: 1150px) {
    .navigation-btn{
        font-size: 12px;
    }
    .language-box {
        padding-left: 1px;
    }
}


@media screen and (min-width: 1200px) {
    .purple-logo {
        padding-left: 20px;
    }
    .search-icon {
        margin: 0 10px;
    }

    .language-box {
        padding-left: 1px;
    }
    .navigation-btn{
        font-size: 12px;
    }
}

@media screen and (min-width: 1253px) {
    .language-box {
        padding-left: 10px;
    }

    .purple-logo {
        padding-left: 50px;
    }
    .navigation-btn{
        font-size: 14px;
    }
}

.search-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
}

.search {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    background-color: #28388E;
    height: 50px;
    border: none;
    font-weight: 600;
    caret-color: transparent;
    cursor: pointer;
    padding: 0 40px;
}