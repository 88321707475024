.news-swiper-container{
    position: relative;
}

.news-swiper-container .swiper-pagination-container {
    flex: 1;
    margin-top: 50px;
    caret-color: transparent;
    width: 50%;
}

.news-swiper-container .navigation-btns-p{
    position: absolute;
    bottom: -3px;
    right: 0;
    z-index: 555;
}


.news-swiper-container .swiper-pagination-container .swiper-pagination {
    text-align: center;
}

.news-swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background: #00A4DC;
}
.news-swiper-container .swiper-pagination .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
}
