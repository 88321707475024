.path-text {
    font-size: 16px;
    color: #55606E;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    margin: 22px 0;
}

.path-text a {
    text-decoration: none;
    color: #55606E;
}

.path-text span {
    text-decoration: none;
    color: #55606E;
}

.commission-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin: 32px 0;
}

.commission-inner p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.commission-inner {
    margin-bottom: 10px;
}


.blue-btn {
    font-family: 'Montserrat', sans-serif;
    color: #00A4DC;
    font-size: 18px;
    font-weight: 600;
    border: none;
    background: transparent;
    margin-right: 10px;
    display: flex;
    align-items: center;
    caret-color: transparent;
}

.btns-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.share-icon {
    display: block;
    margin-top: 3px;
}

.share-arrow {
    position: absolute;
    top: -3px;
    right: -7px;
    transition-duration: 0.1s;
}

.share-btn-div:hover .share-arrow {
    position: absolute;
    top: -8px;
    right: -11px;
}


.blue-btn img {
    display: block;
    margin-left: 10px;
}

.goal-icon {
    width: 48px;
    height: auto;
    margin-right: 5px;
}

.date-text {
    color: #55606E;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.small-titles {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin: 0;
}

.print-only {
    page-break-after: avoid;
    page-break-before: avoid;
}

@media print {

    html, body {
        height: 99%;
        visibility: hidden;
    }

    body * {
        visibility: hidden;
    }

    .print-only, .print-only * {
        visibility: visible;
    }

    .print-only {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-y: hidden;

    }

}

.wrapper {
    width: 100%;
    max-width: 800px;
  max-height: 100%;
    position: relative;
}

.embed-responsive {

    width:100%;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    height: 100%;

}

.embed-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}