.event-card{
    width: 100%;
    height: 100%;
    border-top: 2px solid #55606E;
    display: flex;
    flex-direction: column;
}

.event-title{
    font-size: 18px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 20px;
    margin: 15px 0;
    height: 2.5rem; /* Set a fixed height of 3rem (or adjust to your preference) */
    overflow: hidden; /* Hide any overflow content */
    text-overflow: ellipsis; /* Add an ellipsis (...) to indicate there is more content */
    white-space: pre-wrap;
}

.event-img{
    width: 100%;
    background-position: center;
    background-size:cover;
    min-height: 165px;
    margin-bottom: 22px;
}


.event-inner{
    display:flex;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.event-inner img{
    margin-right: 10px;
}

.event-inner p{
    margin: 0;
}

.event-inner p span{
    color: #828282;
}

.learn-more{
    display: flex;
    align-items: center;
    color: #00A4DC;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 3px 0 1px 31px;
    vertical-align: center;
}