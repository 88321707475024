.player-wrapper {
    /*position: relative;*/
    width: 100%;
    height: 262px;
    /*padding-top: 56.25%; !* 720 / 1280 = 0.5625 *!*/
    margin-bottom: 55px;

}

.player-title{
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #55606E;
    margin-top: 4px;
}

.img-preview{
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: overlay;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}