.calendar-container{
    width: 330px;
    height: 402px;
    border: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
}

.react-datepicker{
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    width: 330px;
    display: flex;
    border: none;
}

.react-datepicker__header{
    background-color: white;
    text-transform: uppercase;
    border-bottom: none;
}


.react-datepicker__month-container{
    width: 330px;
    height: 100%;
    float: none;
    caret-color: transparent;
}
.react-datepicker__current-month{
    color:#28388E;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 25px 0;
}


.react-datepicker__day-name{
    text-decoration: underline;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 28px;
    height: 28px;
    margin: 5px 8px;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before{
    border-color: #28388E;
    width: 12px;
    height: 12px;
    top:50%;
    z-index: 10;
}
.react-datepicker__navigation{
    top:16px;
    width: 40px;
    height: 40px;
}

.react-datepicker__day--today{
    border-radius: 50%;
    background-color: #28388E;
    color: white;
}
.react-datepicker__day--today:hover{
    border-radius: 50%;
    background-color: #28388E;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    border-radius: 50%;
}

.react-datepicker__day:hover{
    border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected{
    background-color: #28388E;
    color: white;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover{
    background-color: #28388E;
    color: white;
}

.react-datepicker__day--outside-month{
    color: rgba(0,0,0,0.5);
}

.note-calendar{
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #55606E;
   padding: 10px 0 0 20px;
    position: relative;
}