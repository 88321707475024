.news-single-box{
width: 100%;
    min-height: 402px;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: overlay;
    padding: 32px 24px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.news-date{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.news-title{
    font-size: 18px;
    font-weight: 500;
    margin-top: 48px;
}

.news-subtitle {
    font-size: 16px;
    font-weight: 400;
    font-family:"Montserrat", sans-serif;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis ellipsis;
    max-height: 50px;
    margin-top: 32px;
}

.news-single-box a{
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: auto;
}

.more-btn-div{
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: auto;
}

.more-btn-div a{
    display: block;
    margin-top: 2px;
}

.more-btn-line{
    width: 2px;
    opacity: 0;
    border: 1.5px solid white;
    overflow: hidden;
    transition-duration: 0.5s;
    transform: translateX(13px);
}

.blue-more{
    border: 1.5px solid #00A4DC;
}

.more-btn-div:hover .more-btn-line{
    width: 50px;
    opacity: 1;
    transition-duration: 0.5s;
}

