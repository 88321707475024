.main-block{
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
    height: 350px;
    background-size: cover;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background-position: center;
}

.main-block h3{
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    margin: 70px 0 32px;
}

.main-block p{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: white;
}

@media screen and (min-width: 800px){
    .main-block{
        height: 734px;
    }
    .main-block h3{
        margin: 423px 0 32px;
        font-size: 20px;
    }
    .main-block p{
        font-size: 16px;
    }
}